@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import '../assets/scss/variables';

html {
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 50px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 50px;
    background: #c9c9c9;
  }

  ::-webkit-scrollbar-thumb {
    background: #777;
    border-radius: 50px;

    &:hover {
      background: #666;
    }
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', Arial, sans-serif;
}

body {
  overflow-y: auto;
  overflow-x: hidden;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  border: none;
  outline: none;
}
