@import '../../assets/scss/variables';

.header {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 150px;

  h3,
  h2 {
    font-weight: 500;
  }

  span {
    margin-left: 10px;
  }

  .show-all-btn {
    position: absolute;
    top: 80px;
    right: 0;
    margin: 0px 15px 10px 5px;
    padding: 10px 15px;
    width: fit-content;
    border-radius: 5px;
    color: #333;
    font-weight: 400;
    font-size: 20px;
    background-color: rgba(196, 196, 196, 0.7);
    box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.2);
    transition: all 0.2s;

    &:hover {
      box-shadow: 3px 3px 6px 1px rgba(0, 0, 0, 0.3);
    }

    &.disabled {
      background-color: rgba(196, 196, 196, 0.3);
      pointer-events: none;
    }
  }
}

.loading-animation,
.error-text {
  position: relative;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-5%, -5%);
}

.error-text {
  color: red;
}

.undo_delete-btn {
  color: $confirm-btn-color;
  transform: translateX(6px);

  &:hover {
    background-color: #b8d8e06e;
  }
}

@media screen and (min-width: 1366px) {
  .header {
    max-width: 1100px;
    margin: auto;
  }
}

@media screen and (max-width: $mobile) {
  .header {
    height: 170px;

    .show-all-btn {
      top: 90px !important;
    }
  }
}

@media screen and (max-width: $mobile_s) {
  .header {
    height: 225px;

    h3 {
      font-size: 14px;
    }
    h2 {
      font-size: 18px;
    }

    .show-all-btn {
      left: 0;
      top: 70px !important;
    }
  }
}

// @media screen and (max-width: 309px) {
//   .header .show-all-btn {
//     top: 70px !important;
//   }
// }

.left-zero {
  left: 0;
  top: 70px !important;
}

.bigger-header {
  height: 225px;
}
