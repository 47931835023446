@import '../../assets/scss/variables';

.modal-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  color: #333;

  .modal-title {
    margin: 10px 0 20px 0px;
    padding-left: 20px;
    padding-bottom: 10px;
    font-weight: 400;
    border-bottom: 1px solid rgb(220, 220, 220);
  }

  .data-fields {
    display: flex;
    flex-direction: column;

    .data-label {
      color: #000;
      width: 100px;
      margin-right: 10px;
    }

    .name-field {
      display: flex;

      input {
        outline: none;
        box-sizing: border-box;
        border: 1px solid #bbb;
        border-radius: 4px;
        margin-bottom: 20px;
        transform: translateY(-10px);

        width: 73%;
        height: 20px;
        padding: 18px 10px;
        font-size: 16px;
        background-color: rgba(236, 236, 236, 0.5);

        &:hover {
          border: 1px solid #000;
        }

        &:focus {
          border: 2px solid rgb(25, 118, 209);
          padding: 17px 9px;
        }

        &.red-border {
          border: 2px solid red;
          padding: 17px 9px;
        }
      }
    }

    .description-field {
      display: flex;
      margin-bottom: 10px;

      textarea {
        outline: none;
        box-sizing: border-box;
        border: 1px solid #bbb;
        border-radius: 4px;
        transform: translateY(-10px);

        width: 73%;
        height: 60px;
        padding: 10px;
        font-size: 16px;
        background-color: rgba(236, 236, 236, 0.5);

        &:hover {
          border: 1px solid #000;
        }

        &:focus {
          border: 2px solid rgb(25, 118, 209);
          padding: 9px;
        }

        &.red-border {
          border: 2px solid red;
          padding: 9px;
        }
      }
    }
  }

  .time-picker {
    display: flex;

    .time-label {
      width: 100px;
      min-width: 50px;
    }

    input {
      background-color: rgba(236, 236, 236, 0.5);
    }
  }

  .modal-footer {
    display: flex;
    margin-top: 5px;
    justify-content: space-between;
    border-top: 1px solid rgb(220, 220, 220);

    .error-message {
      color: red;
      font-weight: 500;
      transform: translateY(16px);
    }
  }
}

.btn {
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  letter-spacing: 0.16px;
  font-weight: 500;
  line-height: 24px;
  background-color: transparent;

  &.add-btn {
    background-color: $primary-color;
    border-radius: 50%;
    color: #fff;
    font-size: 28px;
    width: 48px;
    height: 48px;
    transition: transform 0.3s, background-color 0.3s;
    margin-right: 15px;
    font-weight: 300;
    z-index: 2;
    position: relative;

    &:hover {
      background-color: $primary-color-hover;
      transform: scale(1.05);
    }

    &:active {
      transform: scale(0.95);
    }
  }

  &.confirm-btn {
    color: $confirm-btn-color;
    transform: translateY(5px);

    &:hover {
      background-color: #b8d8e06e;
    }
  }

  &.cancel-btn {
    color: $cancel-btn-color;
    transform: translateY(5px);
    &:hover {
      background-color: #ff7a7a3a;
    }
  }
}

.habit-modal {
  position: absolute;
  z-index: 5;
  top: 30px;
  left: 30px;
  right: 30px;
  bottom: 30px;
  max-width: 500px;
  max-height: 450px;

  margin: auto;
  outline: none;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid $grey-color;
  background-color: #fff;
}

.habit-modal-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
}

// ReactModal Animation:
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.2s;
}
.ReactModal__Overlay--after-open {
  opacity: 1;
}
.ReactModal__Overlay--before-close {
  opacity: 0;
}

//time input size
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.css-1x5jdmq {
  height: 5px !important;
  width: 70px !important;
}

//time input margin and position
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root,
.css-i44wyl {
  margin-left: 20px !important;
  transform: translateX(-10px);
}

@media screen and (max-height: 550px) {
  .modal-content {
    overflow-y: auto;
  }
}

@media (min-height: 550px) and (max-width: $mobile) {
  .modal-content {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

@media (max-width: 505px) {
  .habit-modal {
    max-height: 550px;
  }
}

@media (max-width: $mobile) {
  .btn.add-btn {
    position: fixed;
    bottom: 100px;
    right: 25px;
    width: 54px;
    height: 54px;
    font-size: 30px;
  }

  .add-btn-text {
    display: none;
  }

  .name-input {
    flex-direction: column;

    input {
      margin-top: 13px;
      width: 100% !important;
    }
  }

  .description-input {
    flex-direction: column;
    transform: translateY(-10px);

    textarea {
      transform: translateY(2px) !important;
      width: 100% !important;
    }
  }

  .time-picker {
    transform: translateY(-10px);
    justify-content: space-between;

    .time-label {
      transform: translateY(20px) !important;
      width: 120px !important;
    }

    .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
      width: 100px !important;
    }

    .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
      margin-top: 10px !important;
      transform: translateX(0px);
    }
  }
}
