@import '../../assets/scss/variables';

.navbar {
  position: relative;
  margin: auto;
  height: 50px;
  padding: 5px 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background: transparent;
  color: #222;

  .digi-clock {
    font-weight: 500;
  }

  .user-info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 110px;
    padding: 5px;
    transform: translateX(10px);
    width: fit-content;

    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
    cursor: pointer;

    // img.user-avatar {
    //   width: 30px;
    //   height: 30px;
    //   margin-right: 10px;
    // }

    .user-name {
      pointer-events: none;
    }

    &:hover {
      border-color: #eee;
    }
  }

  div[role='menu'] {
    position: absolute;
    top: 50px;
    right: -15px;
    z-index: 5;
    padding: 10px 0;
    display: flex;
    flex-direction: column;

    background-color: rgb(244, 246, 248);
    border-radius: 5px;
    box-shadow: 2px 3px 4px 3px rgba(0, 0, 0, 0.3);

    opacity: 0;
    pointer-events: none;
    transform: translateY(5px);
    transition: all 0.2s ease-in;

    & > .dropdown-item {
      color: #111;
      outline: none;
      padding: 8px 10px;

      svg {
        transform: translateY(3px);
        margin-right: 3px;
        font-size: 17px;
      }

      &:hover {
        background-color: #ddd;
      }
    }
  }

  div[role='menu'].visible {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
  }
}

@media screen and (min-width: $desktop) {
  .navbar {
    width: 1070px;
  }
}

@media screen and (max-width: $desktop) {
  .navbar {
    margin: 0 30px;
  }
}

@media screen and (max-width: $mobile) {
  .navbar .digi-clock {
    font-size: 1em;
  }
  // .user-info img.user-avatar {
  //   margin-right: 0px;
  // }
}
