@import '../../assets/scss/variables';

.habits-view {
  height: calc(100vh - 230px);
  margin: auto;
  overflow: auto;

  .all_done-checkbox {
    position: absolute;
    top: 150px;
    z-index: 0;
    margin: 0px 0 10px 5px;
    padding: 10px 15px;
    height: 45px;
    border-radius: 5px;
    background-color: rgba(196, 196, 196, 0.7);
    box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.2);
    pointer-events: none;

    .jsx-2134861079.text {
      color: inherit;
    }
  }
}

.habits-list {
  position: relative;

  .check-habit-box {
    position: absolute;
    margin-left: 10px;
    display: flex;
    padding: 10px;
    z-index: 1;
  }

  li {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    margin: 0 15px 40px 5px;
    height: 75px;
    cursor: pointer;
    border-radius: 5px;
    align-items: center;
    box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.2);
    transition: all 0.2s;

    &:hover {
      box-shadow: 3px 3px 6px 1px rgba(0, 0, 0, 0.3);
    }

    .habit-name {
      position: relative;
      left: 30px;
      top: -18px;
    }

    .habit-time {
      position: absolute;
      transform: translateY(16px);
    }

    .delete-btn {
      border-radius: 50%;
      padding: 10px;
      height: 36px;
      transform: translateY(16px);
      position: relative;
      right: -10px;
      z-index: 1;
      cursor: pointer;

      &:hover {
        background-color: rgba(66, 66, 66, 0.2);
        color: #000;
      }
    }
  }

  .no-habit-msg {
    position: absolute;
    left: 50%;
    top: 50px;
    transform: translateX(-50%);
    text-align: center;

    p {
      display: inline;
      color: #555;
    }

    .add-btn-icon {
      display: inline-block;
      background-color: $primary-color;
      border-radius: 50%;
      color: #fff;
      font-weight: 300;
      pointer-events: none;

      position: relative;
      padding-top: 5px;
      font-size: 22px;
      top: 3px;
      margin: 0 5px;
      width: 36px;
      height: 36px;
    }
  }
}

.confirm-dialog {
  position: absolute;
  z-index: 5;
  top: 30px;
  left: 30px;
  right: 30px;
  bottom: 30px;
  max-width: 340px;
  max-height: 150px;

  margin: auto;
  outline: none;
  padding: 18px;
  border-radius: 8px;
  border: 1px solid $grey-color;
  background-color: #fff;
}

.confirm-dialog-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
}

.confirm-dialog-content {
  position: relative;
  width: 100%;
  height: 100%;

  h3 {
    color: #111;
    font-size: 20px;
    font-weight: 500;
  }

  p {
    color: #222;
    font-size: 16px;
    transform: translateY(20px);
  }

  .delete-btn-group {
    position: absolute;
    bottom: 0;
    right: 0;

    & > button {
      margin-left: 5px;
    }
  }
}

.lower-1 {
  top: 160px !important;
}

.lower-2 {
  top: 220px !important;
}

// override the checkbox's text color
span.jsx-2293672674.text,
span.jsx-3904811003.text {
  color: inherit;
}

@media screen and (min-width: $desktop) {
  .habits-view {
    max-width: 1095px;
  }
}

@media screen and (max-width: $mobile_s) {
  .habits-view .all_done-checkbox {
    top: 220px !important;
  }
}
