@import '../../assets/scss/variables';

a {
  color: #fff;
}

.menu {
  position: relative;
  padding: 10px 0 50px 15px;
  min-height: 100vh;
  min-width: 200px;
  max-width: 200px;
  overflow: hidden;
  transition: max-width, min-width 0.5s;
  background: transparent;

  .aib-logo {
    width: 35px;
    position: relative;
    top: 3px;
    left: 8px;
    border-radius: 6px;
  }

  .menu-item .menu-text {
    position: absolute;
    opacity: 1;
    width: 150px;
    transition: opacity 0.4s;
  }

  &.minimized {
    min-width: 80px;
    max-width: 80px;

    .menu-item .menu-text {
      opacity: 0;
    }

    .show-sidebar-btn {
      transform: translateX(-15px);
    }

    form.search-box {
      width: 46px;

      & > button.trigger-search-btn {
        display: block;
      }

      & > input {
        width: 46px;
      }
    }
  }
}

.sidebar-top {
  color: #fff;
  padding: 10px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .show-sidebar-btn {
    position: relative;
    color: #fff;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    align-items: center;
    font-size: 1.5rem;
    z-index: 2;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      box-shadow: 5px 5px 5px rgba($color: #fff, $alpha: 0.2),
        -5px -5px 5px rgba($color: #fff, $alpha: 0.2), -5px 5px 5px rgba($color: #fff, $alpha: 0.2),
        5px -5px 5px rgba($color: #fff, $alpha: 0.2);
    }
  }
}

a.menu-item {
  color: #fff;
  padding: 15px;
  border-radius: 10px 0 0 10px;
  cursor: pointer;
  display: block;
  font-size: 16px;
  display: flex;
  align-items: center;
  position: relative;

  .menu-text {
    display: inline-block;
    position: absolute;
    margin-left: 20px;
  }

  &:hover {
    color: #fff !important;

    .menu-text {
      transition: all 0.1s ease-in;
      transform: scale(1.1);
      margin-left: 28px;
    }

    & svg {
      transition: all 0.1s ease-in;
      transform: scale(1.2);
    }
  }
}

a.menu-item.item-active {
  color: $primary-color !important;
  background: rgba(255, 255, 255, 0.8);
  font-weight: 600;
  position: relative;
  color: $primary-color;

  &::before {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    transform: translateY(-100%);
    box-shadow: 10px 10px 0 0 rgb(238, 238, 249);
  }

  &::after {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    bottom: 0;
    right: 0;
    transform: translateY(100%);
    box-shadow: 10px -10px 0 0 rgb(238, 238, 249);
  }
}

form.search-box {
  box-sizing: border-box;
  position: relative;
  width: 10.6rem;
  margin-bottom: 10px;
  background: rgba($color: #fff, $alpha: 0.8);
  border-radius: $rad;
  transition: width 0.5s;

  & > button.trigger-search-btn {
    position: absolute;
    z-index: 2;
    border-radius: $rad;
    width: 46px;
    height: 46px;
    font-size: 20px;
    cursor: pointer;
    display: none;
    background-color: transparent;

    svg {
      position: absolute;
      top: 15px;
      left: 15px;
    }
  }

  & > input {
    height: $height;
    border: 0;
    color: $color-dark;
    font-size: 1rem;
  }

  & > input[type='search'] {
    outline: 0;
    background: transparent;
    border-radius: $rad;
    appearance: none; //for iOS input[type="search"] roundedness issue. border-radius alone doesn't work
    transition: all $dur cubic-bezier(0, 0, 0.43, 1.49);
    transition-property: width, border-radius;

    width: 100%;
    z-index: 1;
    padding: 0 1rem;
    position: relative;
  }
}

@media (max-width: $mobile) {
  .menu {
    min-width: 130px;
    max-width: 130px;
    padding-left: 10px;

    .aib-logo {
      left: 3px;
    }

    form.search-box {
      width: 7rem;
    }

    &.minimized {
      min-width: 60px;
      max-width: 60px;

      .show-sidebar-btn {
        transform: translateX(-5px) !important;
      }

      form.search-box {
        width: 42px;
        height: 42px;

        & > input {
          width: 42px;
        }

        & > button.trigger-search-btn {
          width: 42px;
          height: 42px;

          svg {
            top: 12px;
            left: 12px;
          }
        }
      }
    }
  }

  a.menu-item svg {
    transform: translateX(-5px);
  }
}
