@import '../../assets/scss/variables';

.login-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: auto;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  color: #555;
  background-color: #ecf0f3;
}

.login {
  margin: 18px 10px;
  width: 430px;
  max-height: 95%;
  overflow: auto;
  padding: 30px;
  border-radius: 40px;
  background-color: #ecf0f3;
  box-shadow: 13px 13px 20px #cbced1, -13px -13px 20px #fff;

  .logo {
    background: url('../../assets/img/aib-logo.jpg') no-repeat center center/cover;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 0 auto;
    box-shadow: 0px 0px 2px #5f5f5f, 0px 0px 0px 5px #ecf0f3, 8px 8px 15px #a7aaaf,
      -8px -8px 15px #fff;
  }

  .title {
    text-align: center;
    font-size: 28px;
    padding-top: 14px;
    letter-spacing: 0.5px;
  }

  .message {
    text-align: center;
    font-size: 15px;
    padding-top: 5px;

    .verify-now {
      color: #111;
      margin: 0 8px;
      transition: all 0.2s;

      &:hover {
        color: $primary-color;
      }
    }
  }

  .auth-form {
    width: 100%;
    padding: 25px 5px 5px 5px;

    .username,
    .password {
      margin-bottom: 25px;
      border-radius: 25px;
      box-shadow: inset 8px 8px 8px #cbced1, inset -8px -8px 8px #fff;

      & > input {
        border: none;
        outline: none;
        max-width: 260px;
        background: none;
        font-size: 18px;
        color: #555;
        padding: 15px 0px 15px 5px;
      }
    }

    .pretty {
      margin-bottom: 15px;
      float: left;

      label {
        font-family: 'Lato', sans-serif;
        font-weight: 400;
      }
    }

    svg {
      height: 20px;
      margin: 0px 8px -3px 25px;
    }

    .auth-btn {
      border: none;
      outline: none;
      cursor: pointer;
      width: 100%;
      height: 51px;
      border-radius: 30px;
      font-size: 20px;
      font-weight: 700;
      color: #fff;

      text-align: center;
      box-shadow: 3px 3px 8px #b1b1b1, -3px -3px 8px #fff;
      background-color: $primary-color;
      transition: all 0.4s;

      &:hover {
        background-color: $primary-color-hover;
      }
      &:active {
        background-color: $cancel-btn-color;
      }
      &.disabled {
        background-color: #aaa;
        pointer-events: none;
      }
    }

    .utilities {
      display: flex;
      justify-content: space-between;
    }

    .link {
      text-align: center;
      color: #444;

      &.suggestion {
        margin-top: 20px;
      }

      a {
        color: #111;
        margin: 0 8px;
        transition: all 0.2s;

        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .utilities {
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 15px;
  }

  input {
    max-width: 150px;
  }
}
