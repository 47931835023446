main {
  background: radial-gradient(circle, rgb(228, 163, 191) 0%, rgb(93, 159, 235) 100%);
}

.main-layout {
  display: flex;

  .content-and-nav {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  .children {
    padding: 20px 5px 20px 20px;
    border-top-left-radius: 10px;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
  }
}
