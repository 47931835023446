/* *** Colors *** */
$primary-color: #ff7235;
$primary-color-hover: #ff9161;
$light-yellow-color: #fcf5ef;
$grey-color: #c4c4c4;
$light-blue-color: #e5e5e5;
$blue-color1: #6db5ca;
$blue-color2: #b8d8e0;

$confirm-btn-color: #2c98f0;
$cancel-btn-color: #ff4040;

// Search box
$rad: 10px;
$dur: 0.3s;
$color-dark: #2f2f2f;
$color-light: #fff;
$color-brand: #57bd84;
$height: 46px;
$btn-width: 46px;

/* *** Screen size for responsiveness *** */
$desktop: 1366px;
$tablet: 768px;
$mobile: 480px;
$mobile_s: 450px;
