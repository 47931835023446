@import '../../assets/scss/variables';

.sleep-container {
  height: calc(100vh - 80px);

  &.has-overflow {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.sleep-cal {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  h3 {
    margin-bottom: 10px;
    font-weight: 500;
  }

  .selector-wrapper {
    margin-bottom: 10px;

    span {
      margin: 0px;
    }

    .separator {
      font-size: 20px;
      font-weight: 500;
      margin: 5px 10px 0 10px;
    }

    .cal-btn {
      border-radius: 5px;
      background-color: transparent;
      font-size: 18px;
      cursor: pointer;
      padding: 2px 10px;
      height: 42px;
      margin-top: 8px;
      transition: all 0.2s;
      color: #1976d2;

      &:hover {
        background-color: rgba(25, 118, 210, 0.04);
        border: none;
      }
    }

    // hide the buttons below Mui Time Picker Dialog
    .css-hlj6pa-MuiDialogActions-root {
      display: none !important;
    }
    .css-14b29qc {
      display: none !important;
    }
  }
}

.results {
  &.inactive {
    display: none;
  }

  h3 {
    text-align: center;
    font-weight: 400;
    margin: 20px auto;
  }

  .reminder-text {
    margin: 20px auto;
    text-align: center;
    color: #555;

    .clear-reminder-time-btn {
      font-size: 16px;
      cursor: pointer;
      color: rgb(38, 132, 255);
      transition: all 0.2s;
      margin-left: 5px;

      &:hover {
        color: rgb(0, 74, 171);
      }
    }
  }

  .calculated-time {
    display: grid;
    grid-template-columns: auto auto auto auto;
    margin-top: 10px;

    .item {
      text-align: center;
      padding: 10px;
      cursor: pointer;
      border-radius: 10px;
      transition: background-color 0.2s ease-in;

      &:hover {
        background-color: rgba(192, 192, 192, 0.3);
      }

      h1 {
        font-weight: 500;
      }

      p {
        font-size: 14px;
        color: #555;
      }
    }
  }

  .learn-more-btn {
    margin-top: 10px;
    background-color: transparent;
    font-size: 16px;
    cursor: pointer;
    padding: 4px 0px;
    margin-bottom: 10px;
    color: rgb(38, 132, 255);
    display: inline-block;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      transform: scaleX(0);
      background-color: rgb(38, 132, 255);
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      transition: all 0.3s ease-out;
    }

    &:hover::after {
      transform: scaleX(1);
    }
  }
}

.more-info {
  opacity: 1;
  transition: opacity 0.2s ease-in;

  &.inactive {
    opacity: 0;

    & > p,
    a {
      display: none;
    }
  }

  & > p {
    margin-bottom: 5px;
    max-width: 650px;
  }

  a {
    color: rgb(38, 132, 255);
    position: relative;

    &:hover {
      text-decoration: underline;
    }
  }
}

.select__single-value.css-qc6sy-singleValue {
  font-size: 18px;
}

.css-4l7j15 {
  background-color: transparent !important;
  border: 1px solid #ccc;
  border-radius: 10px;
}

// style for Mui Time Picker Dialog wrapper
.css-xelq0e-MuiPickerStaticWrapper-content {
  border-radius: 10px !important;
}

//selected number
.css-1nql4ji-MuiTypography-root.Mui-selected {
  color: #000 !important;
}

@media screen and (max-height: 530px) {
  .sleep-container {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media (max-width: 480px) {
  .selector-wrapper {
    .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
      transform: translate(-10px, 8px);
    }
  }
  .results .calculated-time {
    grid-template-columns: auto;
  }
}

@media (min-width: 1024px) {
  .more-info-wrapper {
    text-align: left;
    width: 100%;
    transform: translateX(10%);
  }

  .calculated-time {
    gap: 10px 30px !important;
  }
}

@media (min-width: $desktop) {
  .more-info-wrapper {
    transform: translateX(25%);
  }
}
